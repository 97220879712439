.container-module .password-requirements {
padding-left:var(--spacing-md);list-style-type:none
}

.container-module .modal-body .bottom-separator {
border-bottom:solid 1px var(--color-gray4)
}

.container-module .modal-body .ac-validation-container {
display:block
}

