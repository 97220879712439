.container-module .context-dropdown {
padding:0 var(--spacing-sm);font:inherit;background-color:var(--context-color, --menu-bar-color);border:2px solid rgba(0,0,0,0);border-radius:var(--field-radius);transition:background-color 250ms
}

.container-module .context-dropdown:focus-visible {
border-color:var(--color-focus);outline:0
}

.container-module .context-dropdown:hover {
background-image:linear-gradient(rgba(0, 0, 0, 0.12) 0 0)
}

.container-module .context-dropdown .context-dropdown-content {
max-width:120px
}

.container-module .context-dropdown .context-dropdown-text {
min-width:0
}

.container-module .context-dropdown .context-dropdown-code {
max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap
}

.container-module .context-dropdown-active {
background-image:linear-gradient(rgba(0, 0, 0, 0.2) 0 0)
}

