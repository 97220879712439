.container-module .menu-subitem {
cursor:pointer
}

.container-module .menu-subitem .subitem-shortcut {
display:flex;align-items:center;min-width:50px;padding:0;margin:0;font-size:var(--font-size-sm);text-align:right
}

.container-module .menu-subitem .subitem-shortcut.not-selected {
color:var(--color-gray2)
}

.container-module .menu-subitem.subitem-selected {
color:var(--color-white);background-color:var(--color-primary-action)
}

.container-module .menu-subitem:hover {
color:var(--color-white);background-color:var(--color-hover)
}

.container-module .menu-subitem:hover .subitem-shortcut {
color:var(--color-white)
}

.container-module .menu-subitem .menu-subitem-link {
text-decoration:none
}

.container-module .menu-subitem .subitem-title {
margin-right:var(--spacing-xlg)
}

