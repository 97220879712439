.container-module .menu-logo-container {
z-index:var(--z-index-main-menu);display:flex;width:48px;height:var(--menu-height-default);background-color:var(--menu-bar-color)
}

.container-module .menu-logo-container .menu-logo {
display:flex;align-items:center;justify-content:center;height:100%;padding:0 var(--spacing-xs)
}

.container-module .menu-logo-container .menu-logo img {
display:block;max-width:100%;max-height:100%;image-rendering:optimize-contrast
}

.container-module .menu-logo-container .logo-separator {
margin:var(--spacing-sm) 0 var(--spacing-sm) var(--spacing-s)
}

