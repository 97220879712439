.container-module body:not(.old-menu) .ac-breadcrumbs {
background-color:var(--color-black);border-top:1px solid var(--color-white)
}

.container-module body:not(.old-menu) div:not(.ac-side-panel-content):not(.panel-footer):not(.ac-side-panel-content-body-wrapper):not(.ac-roster-footer)>.ac-footer,.container-module body:not(.old-menu) div:not(.ac-side-panel-content):not(.panel-footer):not(.ac-side-panel-content-body-wrapper):not(.ac-roster-footer) .dc-footer,.container-module body:not(.old-menu) div:not(.ac-side-panel-content):not(.panel-footer):not(.ac-side-panel-content-body-wrapper):not(.ac-roster-footer) .dashboard-content-footer {
left:48px !important;width:100% !important
}

html.container-module {
--menu-bar-color: var(--color-gray1);--color-pink-in-training: #c30f98
}

html.container-module .main-app {
flex-direction:row
}

.container-module #container-module {
display:flex;width:48px
}

.container-module .old-menu #container-module {
display:flex;width:0;height:var(--menu-height-default)
}

.container-module .old-menu #container-module .container-main-menu {
flex-direction:row
}

.container-module .old-menu #container-module .container-main-menu .menu-item-hidden {
visibility:visible
}

.container-module .old-menu #container-module .menu-bar-wrapper {
background-color:var(--menu-bar-color)
}

.container-module .old-menu #container-module .menu-bar-wrapper .menu-bar {
z-index:var(--z-index-main-menu);flex-direction:row;width:calc(100vw - 52px);height:var(--menu-height-default);padding-top:0;padding-left:var(--spacing-xs);background-color:var(--menu-bar-color)
}

.container-module .old-menu #container-module .menu-bar-wrapper .menu-bar.without-property {
width:calc(100vw - 48px)
}

.container-module .old-menu #container-module .menu-bar-wrapper .menu-bar.without-bell {
width:calc(100vw - 48px - 25px)
}

.container-module .old-menu #container-module .menu-bar-wrapper .menu-bar.with-customer-select {
width:calc(100vw - 270px - 48px)
}

.container-module .old-menu #container-module .menu-bar-wrapper .menu-bar.full-width {
width:100vw
}

.container-module .old-menu #container-module .menu-bar-wrapper .menu-bar .items-container {
flex-direction:row;overflow-x:hidden
}

.container-module .old-menu #container-module .menu-bar-wrapper .menu-bar .container-menu-button {
min-width:115px
}

.container-module .old-menu #container-module .menu-status-bar {
top:unset;bottom:1px;left:0;width:100%;height:4px
}

.container-module #application-container {
display:flex;flex-direction:column;width:100%;height:100dvh;min-height:100dvh;padding:0;margin:0;overflow-x:hidden;overflow-y:hidden
}

.container-module #application-container>#root {
display:flex;flex-grow:1;overflow:hidden
}

.container-module #application-container>.ember-view:not(.ac-position-wrapper-addon-base) {
display:flex;flex-direction:column;flex-grow:1;height:100%
}

.container-module #application-container .ac-main-menu {
background-color:var(--menu-bar-color)
}

.container-module #application-container .ac-main-menu .ac-select-wrapper.ac-select-dark:not(:hover)>.ac-dropdown-target-default>.ac-multiselect__control {
background-color:var(--menu-bar-color);box-shadow:var(--menu-bar-color)
}

.container-module #application-container .ac-main-menu .property-select .ember-power-select-trigger {
background-color:var(--menu-bar-color)
}

.container-module .pinned-context-help #application-container {
width:calc(100% - var(--sp-context-help-width) - var(--menu-width))
}

.container-module .pinned-context-help #application-container .ac-main-menu {
position:fixed;right:0
}

.container-module .pinned-context-help #application-container .ac-main-menu .property-container {
margin-top:0
}

.container-module .pinned-context-help #application-container .ac-side-panel-page.ac-side-panel-right {
right:var(--sp-context-help-width)
}

.container-module .pinned-context-help #application-container .ac-side-panel.ac-side-panel-right.sc-ac-side-panel-h {
right:var(--sp-context-help-width)
}

.container-module .pinned-context-help #application-container .ac-side-panel ac-footer .ac-footer-fixed {
left:0;width:100%
}

.container-module #container-module+#application-container #ac-main-menu,.container-module #container-module+#application-container .ac-main-menu {
z-index:calc(var(--z-index-main-menu) - 1)
}

