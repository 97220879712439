.container-module .container-main-menu {
display:flex;flex-direction:column
}

.container-module .menu-bar {
position:relative;z-index:var(--z-index-main-menu);width:var(--menu-width);height:calc(100dvh - var(--menu-height));padding-top:var(--spacing-xs);background-color:var(--color-primary-dark)
}

.container-module .menu-bar .items-container {
width:100%;overflow-y:hidden
}

.container-module .menu-bar.menu-bar-training {
background-color:var(--color-pink-in-training) !important
}

.container-module .selectable-list-dropdown-wrapper {
margin-top:0
}

.container-module .list-header {
width:100%
}

