.container-module .context-help {
z-index:var(--z-index-select-main-menu) !important
}

.container-module .context-help iframe {
width:100%;height:100%;border-width:0
}

.container-module .context-help.ac-side-panel.ac-side-panel-right.sc-ac-side-panel-h {
right:0
}

